<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <BackArrow
        v-if="expenditure"
        :pageTitles="{ heading: expenditure.product_name, content: 'BACK TO REQUESTS' }"
    />
    <success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">{{ successMessage }}</span>
        </template>
    </success-toast>
    <section v-if="expenditure" class="flex items-start justify-between mx-32 text-white text-sm">
        <div class="w-1/4 h-28 flex flex-col p-8 rounded bg-navBgColor mr-8">
            <span class="tracking-widest">Expenditure requests</span>
            <span class="text-2xl leading-loose">{{
                expenditure.department_expenditure_request_count
            }}</span>
        </div>
        <div class="w-1/4 h-28 flex flex-col p-8 rounded bg-navBgColor mr-8">
            <span class="tracking-widest">Capital Expenditure</span>
            <span class="text-2xl leading-loose">{{
                $filters.currencyUSD(expenditure.annual_budget.capital_expenses)
            }}</span>
        </div>
        <div class="w-1/4 h-28 flex flex-col p-8 rounded bg-navBgColor mr-8">
            <span class="tracking-widest">Operating Expenditure </span>
            <span class="text-2xl leading-loose">{{
                $filters.currencyUSD(expenditure.annual_budget.operating_expenses)
            }}</span>
        </div>
        <div
            @click="showBudgetBreakdown = true"
            class="w-1/4 h-28 flex flex-col justify-between cursor-pointer"
        >
            <div
                class="w-full h-28 text-center p-8 rounded border border-primaryColor text-primaryColor text-md uppercase tracking-widest"
            >
                view department budget breakdown
            </div>
        </div>
    </section>
    <section v-if="expenditure" class="mx-32 my-12 bg-white rounded shadow">
        <div class="flex flex-wrap p-10">
            <div class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    submitted by
                </h1>
                <div class="leading-loose">{{ expenditure.created_by }}</div>
            </div>
            <div class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    department
                </h1>
                <div class="leading-loose">{{ expenditure.department }}</div>
            </div>
            <div class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    item name
                </h1>
                <div class="leading-loose">{{ expenditure.product_name }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Are Goods Presently in Stock?
                </h1>
                <div class="leading-loose">{{ expenditure.is_product_in_stock }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Deployment Location
                </h1>
                <div class="leading-loose">{{ expenditure.deployment_location }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Do we Really Need the Goods/Services
                </h1>
                <div class="leading-loose">{{ expenditure.need_product }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Is Expenditure Budget?
                </h1>
                <div class="leading-loose">{{ expenditure.expenditure_budget }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Suggested Supplier
                </h1>
                <div class="leading-loose">{{ expenditure.suggested_supplier }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Unit Price
                </h1>
                <div class="leading-loose">N {{ $filters.currencyUSD(expenditure.unit_cost) }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Quantity
                </h1>
                <div class="leading-loose">{{ expenditure.quantity }}</div>
            </div>
            <div class="w-1/4 py-4 px-3">
                <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">
                    Total cost
                </h1>
                <div class="leading-loose">
                    N {{ $filters.currencyUSD(expenditure.total_cost) }}
                </div>
            </div>
        </div>
        <hr />
        <section class="px-10 py-5">
            <div class="py-5">
                <h1 class="text-primaryColor text-sm leading-loose">Item Description</h1>
                <div class="leading-loose pr-32">{{ expenditure.product_description }}</div>
            </div>
            <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="py-5">
                <h1 class="text-primaryColor text-sm leading-loose">Procurement Strategy</h1>
                <div class="leading-loose pr-32">{{ expenditure.procurement_strategy }}</div>
            </div>
        </section>
        <hr v-if="expenditure.expenditure_request_files.length" />
        <section
            v-if="expenditure.expenditure_request_files.length"
            class="px-10 py-12 grid grid-cols-8 gap-1"
        >
            <div
                v-for="(file, index) in expenditure.expenditure_request_files"
                :key="index"
                class="w-28 h-28 cursor-pointer"
            >
                <div
                    @click="openDocument(file.file_url)"
                    class="flex items-center justify-center w-full h-full border border-borderColor rounded"
                >
                    <img
                        v-if="
                            imageFileType.includes(
                                file.file_url.substr(file.file_url.length - 4)
                            ) ||
                                imageFileType.includes(
                                    file.file_url.substr(file.file_url.length - 3)
                                )
                        "
                        src="@/assets/images/image-format-icon.svg"
                        alt="image icon"
                        :data-fileurl="file.file_url"
                        class="w-1/2"
                    />
                    <img
                        v-if="pdfFileType.includes(file.file_url.substr(file.file_url.length - 3))"
                        src="@/assets/images/pdf-document-icon.svg"
                        alt="pdf document icon"
                        :data-fileurl="file.file_url"
                        class="w-1/2"
                    />
                    <img
                        v-if="
                            wordFileType.includes(file.file_url.substr(file.file_url.length - 3)) ||
                                wordFileType.includes(
                                    file.file_url.substr(file.file_url.length - 4)
                                )
                        "
                        src="@/assets/images/word-document-icon.svg"
                        alt="word document icon"
                        :data-fileurl="file.file_url"
                        class="w-1/2"
                    />
                </div>
                <div @click="openDocument(file.file_url)" class="w-28 text-xs py-3">
                    {{ file.file_name }}
                </div>
            </div>
        </section>
        <hr v-if="isApprovedByLineManager" />
        <section v-if="isApprovedByLineManager" class="px-10 py-12 pr-32 leading-loose">
            <h1 class="capitalize">comment <span class="lowercase">(optional)</span></h1>
            <textarea
                cols="30"
                v-model="comment"
                rows="5"
                class="block w-full border border-borderColor rounded resize-none p-4"
            ></textarea>
            <div class="flex flex-row text-white py-12 justify-end">
                <div
                    @click="approveExpenditureRequest"
                    class="py-2 px-4 mr-3.5 text-center bg-pillApprovedStatus text-sm rounded cursor-pointer"
                >
                    Approve Expenditure
                </div>
                <div
                    @click="rejectExpenditureRequest"
                    class="py-2 px-4 text-center bg-pillRejectedStatus text-sm rounded cursor-pointer"
                >
                    Reject Expenditure
                </div>
            </div>
        </section>
    </section>
    <section
        v-if="showBudgetBreakdown"
        class="w-screen h-screen fixed top-0 z-50 flex items-center justify-center py-12"
        style="background-color: rgba(0,0,0,0.2); overflow: auto; overscroll-y: contain;"
    >
        <div
            class="bg-white w-4/5 overflow-y-scroll overscroll-contain rounded shadow p-16 relative"
            style="height: 90vh"
        >
            <BudgetDataTable :budget="expenditure.annual_budget" />
            <img
                @click="showBudgetBreakdown = false"
                src="@/assets/images/close-icon.svg"
                class="absolute top-6 right-12 cursor-pointer"
            />
        </div>
    </section>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import BudgetDataTable from '@/components/BudgetDataTable.vue'
import SuccessToast from '@/components/notificationToasts/SuccessToast.vue'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import { mapState } from 'vuex'
import errorFunc from '@/util/error'

export default {
    props: { id: String },
    computed: {
        ...mapState(['user']),
        isApprovedByLineManager() {
            let isApprovedByLineManager

            if (this.user && this.expenditure) {
                isApprovedByLineManager = this.expenditure.status === 'Approved by line manager'
            }

            return isApprovedByLineManager
        },
    },
    data() {
        return {
            showBudgetBreakdown: false,
            expenditure: null,
            successMessage: null,
            errorMessage: null,
            comment: null,
            budget: null,
            hasDepartment: true,
            emptyBudget: false,
            imageFileType: ['png', 'jpg', 'jpeg'],
            pdfFileType: ['pdf'],
            wordFileType: ['docx', 'doc', 'txt', 'xlsx', 'xls', 'csv'],
        }
    },
    components: {
        BackArrow,
        BudgetDataTable,
        SuccessToast,
        ErrorToast,
    },
    async mounted() {
        try {
            const { status, data } = await Request.getRequest(
                `expenditure/request/${this.id}`,
                process.env.VUE_APP_FINANCE_BASE_URL
            )
            if (status >= 200 && status < 300) {
                this.expenditure = data.data
            }
        } catch (error) {
            errorFunc(error.response, this.$router)
            this.errorMessage = error.response.data.message
        }
    },
    methods: {
        openDocument(fileUrl) {
            const baseUrl =
                process.env.NODE_ENV === 'production'
                    ? 'http://finance-api.suburbanfiberco.com'
                    : 'http://devops-finance-api.suburbanfiberco.com'
            window.open(`${baseUrl}${fileUrl}`, '_blank')
        },
        async approveExpenditureRequest() {
            try {
                // eslint-disable-next-line
                const confirmBox = confirm('This budget will be approved.')

                if (!confirmBox) {
                    return
                }

                const { status, data } = await Request.postRequest(
                    `expenditure/request/finance/approve?expenditure_request_id=${this.expenditure.id}&comment=${this.comment}`,
                    process.env.VUE_APP_FINANCE_BASE_URL
                )

                if (status === 201) {
                    this.successMessage = data.message
                }
                await this.$router.push({ name: 'expenditure' })
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async rejectExpenditureRequest() {
            try {
                // eslint-disable-next-line
                const confirmBox = confirm('This budget will be rejected.')

                if (!confirmBox) {
                    return
                }

                const { status, data } = await Request.postRequest(
                    `expenditure/request/finance/reject?expenditure_request_id=${this.expenditure.id}&comment=${this.comment}`,
                    process.env.VUE_APP_FINANCE_BASE_URL
                )

                if (status === 201) {
                    this.successMessage = data.message
                }
                await this.$router.push({ name: 'expenditure' })
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
